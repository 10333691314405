const config = {

  app: {
    prefix: "",
    title: "Operations Dashboard (Development)",
    host: "https://ops-dash-ui.dev.arm.gov",
    maintenanceMode: false,
    api: "https://ops-dash-api.dev.arm.gov",
    maintenanceUsers: ["kdumas1", "vtadimeti", "maddoxnd", "hd3", "Sarah.Fillmore1"],
  },
  auth: {
    cas: "https://cas.dev.arm.gov/cas/",
    serviceName: "https://ops-dash-ui.dev.arm.gov",
    sessionExpirationMinutes: 30,
    sessionWarningMinutes: 25,
  },
  graphql: {
    host: "https://postgraphile-api.dev.arm.gov/graphql",
    serviceName: "https://ops-dash-api.dev.arm.gov",
  },
  metadataService: {
    host: "https://armweb-dev.ornl.gov/arm-metadataservice-api",
    ewoURL: "https://armcrfdev.servicenowservices.com/nav_to.do?uri=x_pnnla_engineerin_task.do?sys_id=",
  },
  serviceNow: {
    facilityMetrics: "https://armcrf.servicenowservices.com/nav_to.do?uri=%2F$pa_dashboard.do%3Fsysparm_dashboard[…]rm_editable%3Dundefined%26sysparm_active_panel%3Dfalse",
    assetDocumentationURL: "https://armcrf.servicenowservices.com/kb?id=kb_search&kb_knowledge_base=e1c69f351b391d901e15a82eac4bcb52&kb_category=f8bd43761b9edd50dbd7848ce54bcbd1",
  },
  oss: {
    incidentGroup: "ARM - ARMID Support",
    host: "https://devoss.arm.gov/~perkins/ops/status/services/",
    viewLink: "https://devoss.arm.gov/~perkins/ops/status/oss.php?area=components&action=detail&componentkey=",
    componentService:
      "https://devoss.arm.gov/~perkins/ops/status/services/component-search.php?type=attrib&oper=matches&field=componentkey&show_hidden=on&search=",
  },
  notificationService: {
    host: "https://armweb-dev.ornl.gov/NotificationService",
    senderEmail: "webnotify@arm.gov",
    doNotReplyText: "*** Automated E-Mail - Please do not reply ***",
  },
  sso: {
    logout: "https://armdev-sso.ornl.gov/arm/logout",
  },
  armid: {
    incidentGroup: "ARM - ARMID Support",
  },
  fc: {
    incidentGroup: "ARM - Field Campaign Admins",
  },
  asset: {
    incidentGroup: "ARM - Asset Management Support",
    createShipmentURL: "https://armweb-dev.ornl.gov/ARMMaintenanceTools/#/shipping/new",
  },
  publication: {
    incidentGroup: "ARM - Publications Administrators",
  },
  calibration: {
    incidentGroup: "ADC - Workflow Tools Support",
  },
  location: {
    incidentGroup: "ARM - Asset Management Support",
    mailingList: "adctools",
    duplicateNameWarningTemplate: "<span style='color: #dc3545; font-size: 0.8em;'>" +
      "<b>Warning:</b> Location with name '$NAME' already exists. " +
      "Duplicate location records are not allowed. " +
      "Click <a href='$URL' target='_blank'>here</a> to view the existing location." +
      "</span>",
  },
  incident: {
    groups: [
      { text: "ARMID Support", value: "ARM - ARMID Support" },
      { text: "Campaign Support", value: "ARM - Field Campaign Admins" },
      { text: "Asset Management Support", value: "ARM - Asset Management Support" },
      { text: "App Support", value: "ADC - Workflow Tools Support" },
    ],
  },
  instrumentOperationsDashboard: {
    dqrDetailURL: "https://armweb-dev.ornl.gov/ArchiveServices/DQRService?dqrid=",
    dqaDetailURL: "https://armweb-dev.ornl.gov/dqa/#/edit/",
    dqprDetailURL: "https://armweb-dev.ornl.gov/DQPRSearch/#/dqpr/",
    cmrURL: "https://armweb-dev.ornl.gov/ARMMaintenanceTools/#/CM/Reports/",
    pmrURL: "https://armweb-dev.ornl.gov/ARMMaintenanceTools/#/PM/View/",
    instURL: "https://armcrfdev.servicenowservices.com/inst?id=inst_form&table=x_pnnla_inst_event&view=sp&sys_id=", 
  },
  archiveServices: {
    url: "https://armweb-dev.ornl.gov/ArchiveServices",
  },
}

export default config
