<script>
import "vis-timeline/dist/vis-timeline-graph2d.min.css"
import { Timeline, DataSet } from "vis-timeline/standalone"
import Config from "../../config.js"

export default {
  name: "DatastreamTimeLine",
  props: {
    value: Object,
    minDate: String,
    maxDate: String,
    dataStreamInfo: Array,
    masterStartDate: String,
    masterEndDate: String,
    expandTimeline: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      formattedItems: [],
      instrument: null,
      timelineRef: "timelineContainer",
      timeline: null,
      timelineDataSet: new DataSet([]),
      dataSetVisible: true,
      combinedGroup: { id: 0, content: "", visible: true },
      expandedGroups: [
        { id: 1, content: "DQR", visible: true },
        { id: 2, content: "DQA", visible: true },
        { id: 3, content: "PM", visible: true },
        { id: 4, content: "CM", visible: true },
      ],
      timelineOptions: {
        width: "100%",
        stack: false,
        selectable: true,
        min: this.minDate,
        max: this.maxDate,
        orientation: "top",
      },
      dqrDetailURL: Config.instrumentOperationsDashboard.dqrDetailURL,
      dqaDetailURL: Config.instrumentOperationsDashboard.dqaDetailURL,
      cmrURL: Config.instrumentOperationsDashboard.cmrURL,
      pmrURL: Config.instrumentOperationsDashboard.pmrURL,
    }
  },
  computed: {
    showDQR() {
      return this.$store.getters["instrument/showDQR"]
    },
    showDQA() {
      return this.$store.getters["instrument/showDQA"]
    },
    showCM() {
      return this.$store.getters["instrument/showCM"]
    },
    showPM() {
      return this.$store.getters["instrument/showPM"]
    },
  },
  watch: {
    masterStartDate(newVal) {
      this.updateTimelineWindow()
    },
    masterEndDate(newVal) {
      this.updateTimelineWindow()
    },
    showDQR(newVal) {
      if (this.expandTimeline) {
        this.toggleGroupVisibility("DQR", newVal)
      }
    },
    showDQA(newVal) {
      if (this.expandTimeline) {
        this.toggleGroupVisibility("DQA", newVal)
      }
    },
    showCM(newVal) {
      if (this.expandTimeline) {
        this.toggleGroupVisibility("CM", newVal)
      }
    },
    showPM(newVal) {
      if (this.expandTimeline) {
        this.toggleGroupVisibility("PM", newVal)
      }
    },
    minDate(value) {
      this.setDateRange(value, this.maxDate)
    },
    maxDate(value) {
      this.setDateRange(this.minDate, value)
    },
    expandTimeline(newVal) {
      this.toggleTimeline()
    },
  },
  mounted() {
    this.setupData()
    this.initTimeline() // Initialize the timeline
  },
  methods: {
    updateTimelineWindow() {
      let start, end

      if (this.masterStartDate && this.masterEndDate) {
        start = new Date(this.masterStartDate)
        end = new Date(this.masterEndDate)
      } else {
        start = new Date(this.minDate)
        end = new Date(this.maxDate)
      }

      if (start && end && this.timeline) {
        this.timeline.setWindow(start, end)
      }
    },
    createTooltipContent(item, type) {
      let content = ""
      if (type === "dqr") {
        content = `
          <div style="background-color: #f9f9f9; border: 1px solid #ccc;
          box-shadow: 3px 3px 5px rgba(0,0,0,0.2); font-family: Arial, sans-serif;
          font-size: 12px; line-height: 1.4; border-radius: 4px; padding: 8px;
          overflow: hidden;">
            DQR ID: ${item.id}<br>
            Datastream: ${item.datastream}<br>
            Start Date: ${item.startDate}<br>
            End Date: ${item.endDate}<br>
          </div>
        `
      } else if (type === "dqa") {
        content = `
          <div style="background-color: #f9f9f9; border: 1px solid #ccc;
          box-shadow: 3px 3px 5px rgba(0,0,0,0.2); font-family: Arial, sans-serif;
          font-size: 12px; line-height: 1.4; border-radius: 4px; padding: 8px;
          overflow: hidden;">
            DQANo: ${item.dqaDqaByDqano.dqano}<br>
            Start Date: ${item.dqaDqaByDqano.dqaStartdate}<br>
            End Date: ${item.dqaDqaByDqano.dqaEnddate}<br>
          </div>
        `
      } else if (type === "cmr") {
        content = `
          <div style="background-color: #f9f9f9; border: 1px solid #ccc;
          box-shadow: 3px 3px 5px rgba(0,0,0,0.2); font-family: Arial, sans-serif;
          font-size: 12px; line-height: 1.4; border-radius: 4px; padding: 8px;
          overflow: hidden;">
            CM Reported Date: ${item.reportedDate}<br>
          </div>
        `
      } else if (type === "pmr") {
        content = `
          <div style="background-color: #f9f9f9; border: 1px solid #ccc;
          box-shadow: 3px 3px 5px rgba(0,0,0,0.2); font-family: Arial, sans-serif;
          font-size: 12px; line-height: 1.4; border-radius: 4px; padding: 8px;
          overflow: hidden;">
            PM Reported Date: ${item.reportedDate}<br>
          </div>
        `
      }
      return content
    },
    setupData() {
      // Prepare formattedItems with all data
      const dqrTemp = this.value.dqrs.map((item) => {
        const colorClass = item.pifcardqr2DqrColorCodeByMetricValue.codeDesc
        return {
          data: item,
          group: 1,
          start: item["startDate"],
          end: item["endDate"],
          visible: true,
          className: `timelineItem ${colorClass}`,
          title: this.createTooltipContent(item, "dqr"),
        }
      })
      const dqaTemp = this.value.dqas.map((item) => {
        return {
          data: item,
          group: 2,
          start: item.dqaDqaByDqano["dqaStartdate"],
          end: item.dqaDqaByDqano["dqaEnddate"],
          visible: true,
          className: `timelineItem dqa`,
          title: this.createTooltipContent(item, "dqa"),
        }
      })
      const cmrTemp = this.value.cmrs.map((item) => {
        return {
          data: item,
          group: 4,
          start: item.reportedDate,
          end: item.reportedDate,
          visible: true,
          className: `timelineItem cmr`,
          title: this.createTooltipContent(item, "cmr"),
        }
      })
      const pmrTemp = this.value.pmrs.map((item) => {
        return {
          data: item,
          group: 3,
          start: item.reportedDate,
          end: item.reportedDate,
          visible: true,
          className: `timelineItem pmr`,
          title: this.createTooltipContent(item, "pmr"),
        }
      })
      this.formattedItems = [
        ...dqrTemp,
        ...dqaTemp,
        ...cmrTemp,
        ...pmrTemp,
      ]
    },
    initTimeline() {
      const container = this.$refs.timelineContainer
      this.timelineDataSet = new DataSet()
      this.timeline = new Timeline(container,this.timelineDataSet,[],this.timelineOptions)
      this.timeline.on("click", (properties) => {
        if (properties.item) {
          this.onItemClick(properties.item)
        }
      })
      this.toggleTimeline()
    },
    toggleTimeline() {
      if (!this.timeline) return
      this.timelineDataSet.clear()

      const backgroundClassName = "negative"
      const addBackgroundItems = (groups) => {
        groups.forEach((group) => {
          this.timelineDataSet.add({
            start: this.minDate,
            end: this.maxDate,
            group: group.id,
            className: backgroundClassName,
          })
        })
      }

      if (this.expandTimeline) {
        // Expanded state
        this.timeline.setGroups(this.expandedGroups)
        addBackgroundItems(this.expandedGroups)
        // Apply current toggles
        this.applyCurrentToggles()
        // Add items
        this.formattedItems.forEach((item) => {
          this.timelineDataSet.add(item)
        })
      } else {
        // Collapsed state
        this.timeline.setGroups([this.combinedGroup])
        addBackgroundItems([this.combinedGroup])
        // Add all items to the combined group
        this.formattedItems.forEach((item) => {
          const newItem = { ...item, group: 0 } // Assign to combined group
          this.timelineDataSet.add(newItem)
        })
      }

      this.timeline.redraw()
      this.updateTimelineWindow()
    },
    toggleGroupVisibility(groupName, visibility) {
      if (!this.timeline || !this.expandTimeline) {
        return
      }
      const groupIndex = this.expandedGroups.findIndex((g) => g.content === groupName)
      if (groupIndex !== -1) {
        this.$set(this.expandedGroups, groupIndex, {
          ...this.expandedGroups[groupIndex],
          visible: visibility,
        })
        this.updateTimelineGroups()
      }
    },
    updateTimelineGroups() {
      if (this.timeline && this.expandTimeline) {
        const updatedGroups = new DataSet(this.expandedGroups)
        this.timeline.setGroups(updatedGroups)
        this.timeline.redraw()
      }
    },
    applyCurrentToggles() {
      this.expandedGroups.forEach((group) => {
        if (group.content === "DQR") {
          group.visible = this.showDQR
        } else if (group.content === "DQA") {
          group.visible = this.showDQA
        } else if (group.content === "CM") {
          group.visible = this.showCM
        } else if (group.content === "PM") {
          group.visible = this.showPM
        }
      })
      this.updateTimelineGroups()
    },
    setDateRange(minDate, maxDate) {
      if (this.timeline) {
        const startDate = new Date(minDate)
        const endDate = new Date(maxDate)
        if (!isNaN(startDate) && !isNaN(endDate)) {
          this.timeline.setWindow(startDate, endDate)
        }
      }
    },
    onItemClick(itmId) {
      const itm = this.timelineDataSet.get(itmId)
      let group = itm.group
      // when collapsed, all are in group 0
      if (!this.expandTimeline && group === 0) {
        if (itm.data.id) {
          group = 1 // dqr - id
        } else if (itm.data.dqaDqaByDqano) {
          group = 2 // dqa - dqano
        } else if (itm.data.reportId) { // cm pm
          group = itm.data.reportSiteCode ? 4 : 3 
        }
      }
      if (group == 1) {
        const URL = this.dqrDetailURL + itm.data.id
        window.open(URL, "_blank")
      } else if (group == 2) {
        const URL = this.dqaDetailURL + itm.data.dqaDqaByDqano.dqano
        window.open(URL, "_blank")
      } else if (group == 3) {
        const URL = this.pmrURL + itm.data.reportId
        window.open(URL, "_blank")
      } else if (group == 4) {
        const URL = `${this.cmrURL}${itm.data.reportSiteCode}/${itm.data.reportFacilityCode}/CMR/Get/${itm.data.reportId}`
        window.open(URL, "_blank")
      }
    },
  },
}
</script>

<template>
  <div class="position-relative">
    <div id="timelineId" ref="timelineContainer" />
  </div>
</template>

<style>
#timelineId .vis-tooltip {
  font-size: 14px !important;
  padding: 10px !important;
  max-width: 300px !important;
  width: auto !important;
  white-space: normal !important;
  word-wrap: break-word !important;
  z-index: 1000 !important;
  box-shadow: 0 2px 4px rgba(0,0,0,0.2) !important;
}
#timelineId {
  overflow: visible !important;
}
#timelineId .vis-timeline {
  overflow: visible !important;
}
#timelineId .vis-timeline .vis-itemset .vis-item.negative {
  background-color: #66b561 !important;
  z-index: 0;
}
#timelineView .vis-time-axis .vis-text.vis-minor {
  background-color: rgba(200, 200, 200, 0.1);
}
#timelineView .vis-panel .vis-background {
  background: rgba(254, 254, 254, 0.4);
}
#timelineView .vis-label {
  background: transparent;
  color: black;
  border: none;
}
/***Timeline Items */
#timelineView .vis-content .vis-itemset .vis-item.timelineItem {
  border-radius: 2px !important;
  border: 1px solid #032548;
}
#timelineView .vis-content .vis-itemset .vis-item.timelineItem.Missing {
  background-color: black !important;
}
#timelineView .vis-content .vis-itemset .vis-item.timelineItem.Not.Inspected {
  background-color: white !important;
}
#timelineView .vis-content .vis-itemset .vis-item.timelineItem.Verified {
  background-color: green !important;
}
#timelineView .vis-content .vis-itemset .vis-item.timelineItem.Suspect {
  background-color: yellow !important;
}
#timelineView .vis-content .vis-itemset .vis-item.timelineItem.Incorrect {
  background-color: red !important;
}
#timelineView .vis-content .vis-itemset .vis-item.timelineItem.Note {
  background-color: transparent !important;
}
#timelineView .vis-content .vis-itemset .vis-item.timelineItem.Reprocessed {
  background-color: gray !important;
}
</style>
