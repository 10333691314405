<script>
export default {
  name: "TablePageFilters",
  computed: {
    assignedDQRs: {
      get() {
        return !this.$store.getters["instrument/getTableViewFilters"].includes("assignedDQRs")
      },
      set(value) {
        this.handleChange("assignedDQRs", value)
      },
    },
    assignedDQAs: {
      get() {
        return !this.$store.getters["instrument/getTableViewFilters"].includes("assignedDQAs")
      },
      set(value) {
        this.handleChange("assignedDQAs", value)
      },
    },
    DQPRs: {
      get() {
        return !this.$store.getters["instrument/getTableViewFilters"].includes("DQPRs")
      },
      set(value) {
        this.handleChange("DQPRs", value)
      },
    },
    PMRs: {
      get() {
        return !this.$store.getters["instrument/getTableViewFilters"].includes("PMRs")
      },
      set(value) {
        this.handleChange("PMRs", value)
      },
    },
    CMRs: {
      get() {
        return !this.$store.getters["instrument/getTableViewFilters"].includes("CMRs")
      },
      set(value) {
        this.handleChange("CMRs", value)
      },
    },
    upcomingCalibrations: {
      get() {
        return !this.$store.getters["instrument/getTableViewFilters"].includes("upcomingCalibrations")
      },
      set(value) {
        this.handleChange("upcomingCalibrations", value)
      },
    },
    instTable: {
      get() {
        return !this.$store.getters["instrument/getTableViewFilters"].includes("instTable")
      },
      set(value) {
        this.handleChange("instTable", value)
      },
    },
  },
  methods: {
    handleChange(tableName, value) {
      if (value) {
        this.$store.dispatch("instrument/removeTableViewFilter", tableName)
      } else {
        this.$store.dispatch("instrument/addTableViewFilter", tableName)
      }
    },
  },
}
</script>
<template>
  <b-container>
    <b-row>
      <b-col>
        <div class="d-flex align-items-center">
          <label class="switch">
            <input v-model="assignedDQRs" type="checkbox"/>
            <span class="slider round"/>
          </label>
          <p class="text-light ml-2 mt-2">DQRs</p>
        </div>
      </b-col>
      <b-col>
        <div class="d-flex align-items-center">
          <label class="switch">
            <input v-model="assignedDQAs" type="checkbox"/>
            <span class="slider round"/>
          </label>
          <p class="text-light ml-2 mt-2">DQAs</p>
        </div>
      </b-col>
      <b-col>
        <div class="d-flex align-items-center">
          <label class="switch">
            <input v-model="DQPRs" type="checkbox"/>
            <span class="slider round"/>
          </label>
          <p class="text-light ml-2 mt-2">DQPRs</p>
        </div>
      </b-col>
      <b-col>
        <div class="d-flex align-items-center">
          <label class="switch">
            <input v-model="PMRs" type="checkbox"/>
            <span class="slider round"/>
          </label>
          <p class="text-light ml-2 mt-2">PM</p>
        </div>
      </b-col>
      <b-col>
        <div class="d-flex align-items-center">
          <label class="switch">
            <input v-model="CMRs" type="checkbox"/>
            <span class="slider round"/>
          </label>
          <p class="text-light ml-2 mt-2">CM</p>
        </div>
      </b-col>
      <!-- <b-col>
        <div class="d-flex align-items-center">
          <label class="switch">
            <input v-model="upcomingCalibrations" type="checkbox"/>
            <span class="slider round"/>
          </label>
          <p class="text-light ml-2 mt-2">Calibrations</p>
        </div>
      </b-col> -->
      <b-col>
        <div class="d-flex align-items-center">
          <label class="switch">
            <input v-model="instTable" type="checkbox"/>
            <span class="slider round"/>
          </label>
          <p class="text-light ml-2 mt-2">INSTs</p>
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>

<style scoped>
.switch {
  position: relative;
  display: inline-block;
  width: 43px;
  height: 17px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 13px;
  width: 13px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #2196F3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
</style>
